p {
    line-height: 1.8;
}
.logo-decorev-home img {
    width: 300px;
}

// TITRE
.titre {
    display: inline-block;
    margin-bottom: 30px;
    overflow-x: hidden;
    &:after {
        content:'';
        display: block;
        width: 100%;
        height: 30px;
        margin-top: -25px;
        margin-left: 20px;
    }
    &.bleu:after {
        background-color: rgba($bleu, 0.3);
    }
    &.rose:after {
        background-color: rgba($rose, 0.3);
    }
    &.jaune:after {
        background-color: rgba($jaune, 0.3);
    }
    &.taupe:after {
        background-color: rgba($taupe, 0.3);
    }
    &.orange:after {
        background-color: rgba($orange, 0.3);
    }
}

// BOUTONS
.btn {
    color: #fff !important;
    &.bleu {
        background-color: $bleu;
        &:focus {
            box-shadow:0 0 0 .2rem rgba($bleu, 0.3);
        }
        &:hover {
            background-color: rgb(1, 93, 154);
        }
    }
    &.orange {
        background-color: $orange;
        &:focus {
            box-shadow:0 0 0 .2rem rgba($orange, 0.3);
        }
        &:hover {
            background-color: rgb(229, 130, 0);
        }
    }
    &.taupe {
        background-color: $taupe;
        &:focus {
            box-shadow:0 0 0 .2rem rgba($taupe, 0.3);
        }
        &:hover {
            background-color: rgb(159, 144, 126);
        }
    }
    &.rose {
        background-color: $rose;
        &:focus {
            box-shadow:0 0 0 .2rem rgba($rose, 0.3);
        }
        &:hover {
            background-color: rgb(149, 22, 113);
        }
    }    
}

// RECAPTCHA
.grecaptcha-badge { 
    visibility: hidden; 
}

//IMAGE BACKGROUND
.image-background {
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

//PADDING BETWEEN BLOCK 
.padding-block {
    padding-top: 30px;
    padding-bottom: 30px;
}

//DEFAULT PAGE
.default {
    p {
        text-justify: auto;
    }
    h2 {
        margin: 40px 0;
        color: $bleu;
        text-align: center;
    }
    h5 {
        margin-top: 20px;
        color: $bleu;
    }
}


//DIVERS
.cursor-pointer {
    cursor: pointer;
}