#contactez-nous {
    background-color: $gray-100;
    .titre {
        margin-top: 30px;
    }
    .texte-recaptcha {
        font-size: 0.8em;
        color: $gray-600;
    }
    .button-wrapper {
        margin-top: -30px;
    }
    .contact-informations {
        border-left: 5px solid $orange;
        a {
            color: #000;
            &:hover {
                color: $orange;
            }
        }
        ul {
            padding: 0;
            li {
                list-style: none;
            }
        }
    }
}