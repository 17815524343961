#home-realisations {
    img.realisation-image {
        height: 250px;
        width: 100%;
        margin-bottom: 25px;
        object-fit: cover;
    }
    img.realisation-modal-image {
        height:100%;
        width: 100%;
        max-height: calc(100vh - 5rem);
        object-fit: contain;
    }
}
#lightbox {
    display: flex;
    flex-direction: column-reverse;
    .lb-close {
        margin-bottom: 5px;
    }
}