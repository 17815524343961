#qualifications {
    img {
        height: 100px;
        padding-top: 10px;
        transition: all 0.5s;
    }
    a {
        text-decoration: none;
        color: #000;
        transition: all 0.3s;
        &:hover  {
            font-weight: 600;
            img {
                padding: 0;
            }
        }
    }
    a[disabled=""] {
        pointer-events: none;
    }
}