#home-entreprise {
    font-size: 1.15em;
    line-height: 180%;
    .swiper-container {
        width: 100%;
        height: 300px;
    }
    .swiper-slide {
        padding: 0px 80px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 300px;
        .image-swiper {
            height: 300px;
            width: 30%;
            max-width: 300px;
            object-fit: cover;
        }
        .texte-swiper {
            background-color: #F0F1F2;
            position: relative;
            border-left: solid 10px rgba($rose, 0.3);
        }
    }
    
    .gallery-thumbs {
        box-sizing: border-box;
        padding: 30px 0 10px 0;
        height: 20%;
    }
    .gallery-thumbs .swiper-slide {
        font-weight: 400;
        color: $gray-500;
        font-size: 1em;
        padding: 0px;
        cursor: pointer;
    }
    .gallery-thumbs .swiper-slide-thumb-active {
        color: $bleu;
        font-size: 1.3em;
        font-weight: 600;
    }
    .mobile-version {
        display: none;
    }
    @include media-breakpoint-down(md) {
        .desktop-version {
            display: none;
        }
        .mobile-version {
            display: initial;
        }
    }
}