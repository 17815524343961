#footer {
  position: relative;
  background-color: $bleu;
  color: #fff;
  a {
    color: #fff !important;
  }
  .description {
    font-size: 0.8em;
    color: $bleu-light;
  }
  .logo-symbol {
    width: 50px;
    position: absolute;
    right: 20px;
    bottom: 20px;
    opacity: 0.8;
    @include media-breakpoint-down(md) {
      position: initial;
      margin-top: 10px;
    }
    &:hover {
      opacity: 1;
    }
  }
}
