.navbar {
    background-color: #f0f1f2;
    .nav-link {
        color: #000 !important;
        text-transform: uppercase;
        font-size: 0.9em;
    }
    .nav-item-animation {
        font-weight: 600;
        &:after, &.active:after {
            content:'';
            display: block;
            width: 0%;
            height: 20px;
            margin-top: -25px;
            margin-left: 20px;
            @keyframes slidein {
                from {
                width: 0%;
                }
                to {
                width: 90%;
                }
            }
        }
        &:hover, &.active  {
            &:nth-child(1):after {
                animation: slidein 0.5s forwards;
                background-color: rgba($jaune, 0.3);
            }
            &:nth-child(2):after {
                animation: slidein 0.5s forwards;
                background-color: rgba($bleu, 0.3);
            }
            &:nth-child(3):after {
                animation: slidein 0.5s forwards;
                background-color: rgba($orange, 0.3);
            }
            &:nth-child(4):after {
                animation: slidein 0.5s forwards;
                background-color: rgba($taupe, 0.3);
            }
            &:nth-child(5):after {
                animation: slidein 0.5s forwards;
                background-color: rgba($rose, 0.3);
            }
            &:nth-child(6):after {
                animation: slidein 0.5s forwards;
                background-color: rgba($bleu-light, 0.3);
            }
        }
    }
    img.navbar-brand {
        width: 60px;
        margin: 0;
        visibility: visible;
        opacity: 1;
        filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.1));
        transition: all 0.3s;
        &.display-navbar-brand {
            width: 0;
            opacity: 0;
            visibility: hidden;
        }
        @include media-breakpoint-down(md) {
            width: 60px !important;
            opacity: 1 !important;
            visibility: visible !important;
        }
    }
}
