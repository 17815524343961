#header {
    position: relative;
    background-color: #FCFCFC;
    background-image: url('../images/blizzard.png');
    h1 {
        font-size: 1em !important;
        user-select: none;
    }
    .logo-decorev-home {
        position: relative;
    }
    .element-graphique {
        width: 30vw;
        height: 100px;
        position: absolute;
        &.right {
            background: linear-gradient(to right, $jaune, rgba($jaune, 0.6));
            top: 0;
        }
        &.left {
            background: linear-gradient(to left, $jaune, rgba($jaune, 0.6));
            bottom: 0;
            right: 0;
        }
    }
}