#page-header {
    .page-header-banniere {
        height: 200px;
        background-size: cover;
        background-position: center;
        position: relative;
        &:after {
            content: '';
            height: 100%;
            width: 100%;
            display: block;
        }
        &.bleu:after {
            background-color: rgba($bleu, 0.8);
        }
        &.rose:after {
            background-color: rgba($rose, 0.8);
        }
        &.taupe:after {
            background-color: rgba($taupe, 0.8);
        }
        &.orange:after {
            background-color: rgba($orange, 0.8);
        }
    }
    .page-header-titre {
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
        position: absolute;
    }
}