#page-photo-texte {
    font-size: 1.1em;
    .image-background:after {
        content: '';
        width: 40px;
        height: 100%;
        background-color: rgba($rose, 0.4);
        float: right;
        margin: 30px -20px 0px 0px;
    }
    a {
        color: $warning;
        svg {
            font-size: 1.1em;
        }
    }
    .image-background {
        min-height: 280px;
    }
}