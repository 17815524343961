#home-banniere {
  .swiper-container {
    width: 100%;
    height: calc(100vh - 259px);
  }
  .swiper-slide {
    background-position: center;
    background-size: cover;
    .contenu-banniere {
      position: absolute;
      bottom: 80px;
      padding-left: 10vw;
      background-color: rgba($bleu, 0.7);
      width: 50%;
      @include media-breakpoint-down(md) {
        width: 60%;
      }
      @include media-breakpoint-down(xs) {
        width: 100%;
        padding: 10px !important;
      }
      .titre-banniere {
        font-size: 2.5em;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
      }
      .texte-banniere {
        font-size: 1.2em;
      }
    }
  }
}
