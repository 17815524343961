#home-metiers {
    .image-metier {
        height: 120px;
        background-size: cover;
        background-position: center;
        position: relative;
        &:after {
            content: '';
            height: 100%;
            width: 100%;
            display: block;
        }
        &.bleu:after {
            background-color: rgba($bleu, 0.8);
        }
        &.rose:after {
            background-color: rgba($rose, 0.8);
        }
        &.taupe:after {
            background-color: rgba($taupe, 0.8);
        }
        &.orange:after {
            background-color: rgba($orange, 0.8);
        }
    }
    .titre-metier {
        width: 100%;
        position: absolute;
        color: #fff;
        top: 50%;
        transform: translateY(-50%);
    }
    .block-text {
        width:100%;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }
}